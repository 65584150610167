import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromCart from './cart.reducer'
import { Address } from '../../../../../common/models/address'
import { ruCountryId } from '../../../shared/utils/constants/country'
import { UpdatePersonalDataInCartRequest } from '../../../../../common/models/update-personal-data-in-cart-request'
import { PreorderData, preorderData } from '../../../shared/utils/order/preorder-data'
import { addFakeDeliveryMethods } from '../../../features/delivery/pipes/add-fake-delivery-methods.pipe'
import { selectClientPartner } from '../../../../../common/store/jwt/select-client-partner'
import { orderOrCartDelivery, orderOrCartDeliveryOffice } from '../../../shared/utils/delivery/order-or-cart-delivery'
import { advanceCostsDeliveryMethods } from '../../../features/delivery/pipes/advance-costs-delivery-methods.pipe'
import { selectCountries } from 'client/src/app/model/config.selectors'
import { AppDeliveryMethodData } from '../../../features/delivery/lib/delivery-method-data'
import { Country } from 'common/models/country'

//TODO: Это предыдущий вариант реализации расчета итоговой суммы и бонусов, используемой на фронте. После тестинга нового функционала - это можно будет удалить

// function calculateTotalValue(
//   currentCurrency: ExtendedCurrency | undefined,
//   itemsAndProducts: CartItemAndProduct[] = [],
// ): number {
//   let result = 0
//   for (const { item, quantity } of itemsAndProducts) {
//     const prices = item?.prices
//
//     if (prices) {
//       result += (prices.find(p => p.iso_code === currentCurrency?.iso_code)?.price ?? 0) * (quantity || 0)
//     }
//   }
//
//   return result
// }
//
// function calculateTotalValueBonuses(itemsAndProducts: CartItemAndProduct[] = []): number {
//   let result = 0
//   for (const { item, quantity } of itemsAndProducts) {
//     const bonusPrice = item?.bonus_price
//
//     if (bonusPrice) {
//       result += bonusPrice * (quantity || 0)
//     }
//   }
//   return result
// }

// export const selectCartTotal = createSelector(selectCartItems, selectCurrency, (items, currency) =>
//   calculateTotalValue(currency, items),
// )
// export const selectCartTotalBonuses = createSelector(selectCartItems, calculateTotalValueBonuses)

export const selectCartState = createFeatureSelector<fromCart.State>(fromCart.cartFeatureKey)
export const selectCart = createSelector(selectCartState, state => state.cart)

export const selectCartItems = createSelector(selectCart, cart => cart?.items_products_structs)

export const selectCartGroupOrder = createSelector(selectCart, cart => {
	return Boolean(cart?.group_order)
})

export const selectIsCartGroupOrderMain = createSelector(selectCart, cart => {
	return Boolean(cart?.group_order?.is_main)
})

//TODO: Разобраться с тсИгнорами, прокинуть интерфейс для prices

// @ts-ignore
export const selectCartTotalNew = createSelector(selectCartState, state => state?.prices?.price?.full_price)

// @ts-ignore
// TODO: Переименовать попозже как время будет
export const selectTotalTest = createSelector(selectCartState, state => state?.prices?.price?.products_price_in_rub)
// @ts-ignore
export const selectCartTotalPriceIsoCode = createSelector(selectCartState, state => state?.prices?.price?.iso_code)

export const selectCartTotalBonusesNew = createSelector(
	selectCartState,
	// @ts-ignore
	state => state?.prices?.price?.products_price_in_point
)

// Есть ли в корзине активационные продукты
export const selectHasStarterKit = createSelector(
	selectCart,
	cart => cart?.items_products_structs?.some(item => item.product?.is_activate_kit) ?? false
)

export const selectCartItemsPrice = createSelector(
	selectCartState,
	// @ts-ignore
	state => state?.prices?.price?.products_price_in_rub
)
export const selectCartGiftItems = createSelector(selectCart, cart => cart?.gift_items_products_structs ?? [])

export const selectCartChanging = createSelector(selectCartState, state => state.isCartChanging)
export const selectCartLoading = createSelector(selectCartState, state => state.isCartLoading)

export const selectCartDelivery = createSelector(selectCart, orderOrCartDelivery)

export const selectCartDeliveryPrice = createSelector(
	selectCartDelivery,
	// Важно учитывать различие между price === undefined и price === 0. В первом случае это значит,
	// что доставка не посчиталась, во втором - доставка бесплатная
	(delivery): number | undefined => delivery?.delivery?.price
)

/** Determines whether it is possible to move to next page on cart page */
export const selectDisabledNext = createSelector(
	selectCartItems,
	selectCartLoading,
	selectCartChanging,
	(items, loading, changing) => {
		return items?.length === 0 || loading || changing
	}
)

export const selectCartCity = createSelector(selectCart, cart => cart?.city)

export const selectUserCountryId = createSelector(selectCart, cart => cart?.user_country_id)

export const selectCountry = createSelector(selectCart, selectCountries, (cart, countries): Country | null => {
	let country = null
	const country_id = cart?.user_country_id || null

	if (countries !== null) {
		country = countries.find(c => c.id === country_id)
		if (country === undefined) {
			country = null
		}
	}

	return country
})

export const selectCartCountry = createSelector(selectCart, cart => cart?.country)

export const selectCartRegion = createSelector(selectCart, cart => cart?.region)

export const selectCartIsInternational = createSelector(selectCartCountry, country => country?.id !== ruCountryId)

export const selectCartDeliveryMethods = createSelector(
	selectCart,
	selectClientPartner,
	(cart, user): AppDeliveryMethodData[] => addFakeDeliveryMethods(advanceCostsDeliveryMethods(cart), user)
)

export const selectYandexTimeDelivery = createSelector(selectCart, cart => cart?.yandex_delivery?.delivery_time?.data)

export const selectCurrentYandexTimeDelivery = createSelector(selectCart, cart => cart?.yandex_delivery?.interval)

export const selectCartDeliveryMethod = createSelector(
	selectCart,
	selectCartDeliveryMethods,
	(cart, methods): AppDeliveryMethodData | undefined =>
		methods.find(methodData => methodData.method === cart?.delivery)
)

export const selectCartDeliveryOffice = createSelector(selectCart, orderOrCartDeliveryOffice)

export const selectCartDeliveryAddress = createSelector(selectCartDelivery, (delivery): Address | undefined => {
	return delivery?.delivery?.address
})

export const selectCartAdvanceCosts = createSelector(selectCart, cart => cart?.advance_costs)

export const selectCartRuPostCourierDeliveryKind = createSelector(selectCartDelivery, delivery =>
	delivery?.method === 'RuPost' ? delivery.delivery.courier_kind : undefined
)

export const selectCartRuPostToOfficeDeliveryKind = createSelector(selectCartDelivery, delivery =>
	delivery?.method === 'RuPost' ? delivery.delivery.to_office_kind : undefined
)

export const selectCartDpdDeliveryKind = createSelector(selectCartDelivery, delivery =>
	delivery?.method === 'DPD' ? delivery.delivery.kind : undefined
)

export const selectCartOrder = createSelector(selectCart, cart => cart?.order)

export const selectCartStock = createSelector(selectCart, cart => cart?.stock)
export const selectCartCurrency = createSelector(selectCart, cart => cart?.currency_id)

export const selectFreeShipping = createSelector(selectCart, cart => cart?.free_shipping)

export const selectFreeShippingStock = createSelector(selectCart, cart => cart?.free_delivery_stock)

export const selectCartPersonalData = createSelector(
	selectCart,
	(cart): UpdatePersonalDataInCartRequest => ({
		name: cart?.name,
		phone: cart?.phone,
		email: cart?.email,
		iin: cart?.iin,
		comment: cart?.comment
	})
)

export const selectCartPreorderData = createSelector(
	selectCartItems,
	selectCartStock,
	(cartItems, stock): PreorderData => {
		return preorderData(cartItems || [], stock?.id ?? 0)
	}
)

export const selectCartUnpayedOrders = createSelector(selectCart, cart => cart?.orders_payable)
